import React from 'react'
import styles from './About.module.scss'
import { Grid, Typography } from '@mui/material'
import CommonBanner from '../../components/about/CommonBanner'
import img from '../../asserts/about/about.jpg'
import AboutUsSection from '../../components/AboutUsSection/AboutUsSection'
import WorkProcess from '../../components/WorkProcess/WorkProcess'
import OurClients from '../../components/OurClients/OurClients'
import RecruitForm from '../../components/RecruitForm/RecruitForm'

const About = () => {
    return (
        <Grid className={styles.aboutContainer}>
            <CommonBanner
                bannerImg={img}
                label={'About Us'}
                heading={'Know About Us'}
                desc={"Collaboratively administrate empowered markets plug and play networks dynamically procrastinated "}
            />
            <AboutUsSection />
            <WorkProcess />
            <OurClients />
            <RecruitForm />
        </Grid>
    )
}

export default About