import { Grid } from '@mui/material'
import React, { useState } from 'react'
import CommonBanner from '../../components/about/CommonBanner'
import LetsTalk from '../../components/LetsTalk/LetsTalk'
import ContactForm from '../../components/ContactForm/ContactForm'
import img from '../../asserts/contact/about.png'
import styles from './Contact.module.scss'

const Contact = () => {

  return (
    <Grid className={styles.contactContainer}>
      <CommonBanner
        bannerImg={img}
        label={'Contact Us'}
        heading={'Feel Free Reach Us'}
      />
      <LetsTalk />
      <ContactForm />
    </Grid>
  )
}

export default Contact