import React from 'react'
import styles from './HeadingText.module.scss'
import { Grid, Typography } from '@mui/material'

export interface IHeadingText {
    heading: string;
    subHeading: string;
    desc: string
}
const HeadingText = ({ heading, subHeading, desc }: IHeadingText) => {
    return (
        <Grid className={styles.headingTextContainer}>
            <Typography variant='h4' fontSize={32} fontWeight={600} sx={{ color: "#11467A" }}>{heading}</Typography>
            <Typography fontSize={16} fontWeight={500} sx={{ color: "#03274A", marginBlock: 1.6 }}>{subHeading}</Typography>
            <Typography fontSize={14} fontWeight={400} sx={{ color: "#6C747C" }}>{desc}</Typography>
        </Grid>
    )
}

export default HeadingText