import React from 'react'
import styles from './WorkProcess.module.scss'
import { Grid, Typography } from '@mui/material'
import Heading from '../Heading/Heading'
import { workProcess, workProcess1, } from '../../data/header'

const WorkProcess = () => {
    return (
        <Grid className={styles.workProcessContainer}>
            <Heading
                IsLabel={true}
                label='Work Process'
                heading={'How We Work'}
            />
            <Grid className={styles.cardContainer}>
                {workProcess.map((item) => {
                    return (
                        <Grid className={styles.card}>
                            <img src={item.icon} width={40} height={40} />
                            <Typography variant='h4' fontSize={18} sx={{ color: '#37393F', marginBlock: 2 }} fontWeight={600}>{item.heading}</Typography>
                            <Typography lineHeight={1.6} fontSize={15} sx={{ color: "#7D7D7D" }}>{item.desc}</Typography>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid className={styles.cardContainer}>
                {workProcess1.map((item) => {
                    return (
                        <Grid className={styles.card}>
                            <img src={item.icon} width={40} height={40}/>
                            <Typography variant='h4' fontSize={18} sx={{ color: '#37393F', marginBlock: 2 }} fontWeight={600}>{item.heading}</Typography>
                            <Typography  lineHeight={1.6} fontSize={15} sx={{ color: "#7D7D7D" }}>{item.desc}</Typography>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default WorkProcess