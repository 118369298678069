import { MdOutlineEmail } from "react-icons/md";
import { LuMapPin } from "react-icons/lu";
import { FiPhone } from "react-icons/fi";
import icon from '../asserts/about/Hiring.svg'
import icon1 from '../asserts/about/Analysis.svg'
import icon2 from '../asserts/about/Social media.svg'
import icon3 from '../asserts/about/Customer service.svg'
import icon4 from '../asserts/about/Recruitment.svg'
import strategyimg from '../asserts/home/Frame 25.png'
import strategyimg2 from '../asserts/home/image 1.png'
import strategyimg3 from '../asserts/home/Frame 25 (1).png'
import person1 from '../asserts/icons/Ellipse 1 (1).png'
import person2 from '../asserts/icons/Ellipse 1 (2).png'
import person3 from '../asserts/icons/Ellipse 1.png'
import process_1 from '../asserts/about/Job search.svg'
import process_2 from '../asserts/about/Target.svg'
import process_3 from '../asserts/about/Job search (1).svg'
import process_4 from '../asserts/about/Interview.svg'
import process_5 from '../asserts/about/Email.svg'
import process_6 from '../asserts/about/Handshake.svg'
import client_1 from '../asserts/about/Hitachi 1.png'
import client_2 from '../asserts/about/cognizant  1.png'
import client_3 from '../asserts/about/microsoft 1.png'
import client_4 from '../asserts/about/onward tech  1.png'
import client_5 from '../asserts/about/otusonellp 1.png'
import client_6 from '../asserts/about/image 129.png'


export const topbar = [
    {
        "id": 1,
        "icon": <FiPhone style={{ color: "F05836" }} />,
        "label": "Phone",
        "subLabel": "(206) 299-6405"
    },
    {
        "id": 1,
        "icon": <MdOutlineEmail style={{ color: "F05836" }} />,
        "label": "Email",
        "subLabel": "Info@edgeconnectsolutions.com"
    },
]
export const menus = [
    {
        "id": 1,
        "label": "HOME",
        "path": "/"
    },
    {
        "id": 2,
        "label": "ABOUT US",
        "path": "/about"
    },
    {
        "id": 3,
        "label": "SERVICES",
        "path": "/service"
    },
    {
        "id": 4,
        "label": "CAREER",
        "path": "/career"
    },
    {
        "id": 5,
        "label": "CONTACT US",
        "path": "/contact"
    }
]
export const weProvide = [
    {
        "id": 1,
        "icon": icon,
        "label": "Campus",
        "span": "hiring"
    },
    {
        "id": 2,
        "icon": icon1,
        "label": "Corporate ",
        "span": "Training"
    },
    {
        "id": 3,
        "icon": icon2,
        "label": "Digital",
        "span": "Marketing"
    }
]
export const weProvide1 = [
    {
        "id": 4,
        "icon": icon3,
        "label": "IT Recruitment",
        "span": "and Support"
    },
    {
        "id": 5,
        "icon": icon4,
        "label": "Contract permanent",
        "span": "Recruitment"
    }
]
export const record = [
    {
        "id": 1,
        "number": "100%",
        "label": "satisfied customers",
    },
    {
        "id": 2,
        "number": "100%",
        "label": "Projects Completed",
    },
    {
        "id": 3,
        "number": "4+",
        "label": "countries clients collaborated",
    },
    {
        "id": 4,
        "number": "10+",
        "label": " workers employed",
    }
]
export const strategy = [
    {
        "id": 1,
        "img": strategyimg,
        "heading": "BUSINESS PLANNING, STRATEGY & EXECUTION",
        "desc": "Crafting and implementing business plans, strategies, and execution tactics tailored for staffing company success and growth",
    },
    {
        "id": 2,
        "img": strategyimg2,
        "heading": "FINANCIAL PROJECTIONS AND ANALYSIS",
        "desc": "Providing comprehensive financial projections and analysis tailored to optimize performance and profitability for staffing companies",
    },
    {
        "id": 3,
        "img": strategyimg3,
        "heading": "INTERNATIONAL BUSINESS OPPORTUNITIES",
        "desc": "Exploring global markets and partnerships to leverage international business opportunities and expand the reach of staffing services",
    }
]
export const testimonial = [
    {
        "id": 1,
        "img": person1,
        "name": "Amma Watson",
        "designation": "VP Sales",
        "desc": "Nullam in posuere enim. Nunc vulputate quam vel rhoncus sollicitudin. Duis ac sem feugiat, elementum quam vitae, bibendum arcu. Nulla aliquam odio.",
    },
    {
        "id": 2,
        "img": person2,
        "name": "Jacob Cummins",
        "designation": "Director",
        "desc": "Tuurpis egestas. Nunc a convallis ipsum, nec volutpat sem. bibendum arcu. Nulla aliquam odio.Proin non urna sed metus lacinia facilisis id id ante.",
    },
    {
        "id": 3,
        "img": person3,
        "name": "Jacob Cummins",
        "designation": "Director",
        "desc": "Tuurpis egestas. Nunc a convallis ipsum, nec volutpat sem. bibendum arcu. Nulla aliquam odio.Proin non urna sed metus lacinia facilisis id id ante.",
    }
]
export const workProcess = [
    {
        "id": 1,
        "icon": process_1,
        "heading": "Identify Job Requirements",
        "desc": "The process begins with understanding the client's needs and defining the job requirements, including technical skills, experience level, and other qualifications.",
    },
    {
        "id": 2,
        "icon": process_2,
        "heading": "Developing a Targeted Sourcing Strategy",
        "desc": "Recruiters use various channels to source potential candidates, such as job boards, social media, professional networks, referrals, and recruitment agencies.",
    },
    {
        "id": 3,
        "icon": process_3,
        "heading": "Sourcing Candidates",
        "desc": "Recruiters use various channels to source potential candidates, such as job boards, social media, professional networks, referrals, and recruitment agencies.",
    }
]
export const workProcess1 = [
    {
        "id": 4,
        "icon": process_4,
        "heading": "Screening and Interviewing",
        "desc": "Candidates are screened based on their resumes and qualifications. Qualified candidates are then invited for interviews, which may include technical assessments to evaluate their skills and fit for the position.",
    },
    {
        "id": 2,
        "icon": process_5,
        "heading": "Selection and Offer",
        "desc": "After interviews, the most suitable candidate is selected for the position. The recruiter negotiates salary and other terms with the candidate on behalf of the client and extends the job offer."
    },
    {
        "id": 3,
        "icon": process_6,
        "heading": "Onboarding and Follow-up",
        "desc": "Once the candidate accepts the offer, the recruiter facilitates the onboarding process, including paperwork and orientation. After the candidate starts the job, the recruiter may follow up to ensure a smooth transition and address any concerns."
    }
]
export const clients = [
    {
        "id": 4,
        "icon": client_1,
    },
    {
        "id": 2,
        "icon": client_2,
    },
    {
        "id": 3,
        "icon": client_3,
    },
    {
        "id": 4,
        "icon": client_4,
    },
    {
        "id": 5,
        "icon": client_5,
    },
    {
        "id": 6,
        "icon": client_6,
    }
]
export const services = [
    {
        "id": 4,
        "label": "Managed Business IT Services",
    },
    {
        "id": 2,
        "label": "Outsourced IT Consultants",
    },
    {
        "id": 3,
        "label": "Enterprise Solutions",
    },
    {
        "id": 4,
        "label": "Managed IT Staffing Services",
    },
    {
        "id": 5,
        "label": "Outsourced IT Consultants",
    },
    {
        "id": 6,
        "label": "Enterprise Solutions",
    }
]
