import React from 'react'
import styles from './LetsTalk.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import img from '../../asserts/contact/Image.png'
import { FaSquare } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";



const LetsTalk = () => {
    return (
        <Grid className={styles.letsTalkContainer}>
            <Grid>
                <img src={img} />
            </Grid>
            <Grid>
                <Typography variant='h5' fontSize={18} fontWeight={500} sx={{ color: "#37393F" }}><FaSquare fontSize={14} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />Let's Talk</Typography>
                <Typography variant='h4' fontSize={35} fontWeight={700} marginBlock={2} sx={{ color: "#37393F" }}>Get in touch with us</Typography>
                <Typography marginBlockStart={2} marginBlockEnd={8} fontSize={16} sx={{ color: "#7D7D7D" }}>Completely synergize resource taxing relationships via premier niche ynamically innovate state of the art customer service.</Typography>
                <Box>
                    <FiPhone fontSize={21} />
                    <Box>
                        <Typography fontSize={14} sx={{ color: '#7D7D7D' }}>Have any question?</Typography>
                        <Typography variant='h5' fontSize={15} fontWeight={500} sx={{ color: "#37393F" }}>(206) 299-6405 </Typography>
                    </Box>
                </Box>
                <Box>
                    <MdOutlineEmail fontSize={21} />
                    <Box>
                        <Typography fontSize={14} sx={{ color: '#7D7D7D' }}>Email</Typography>
                        <Typography variant='h5' fontSize={15} fontWeight={500} sx={{ color: "#37393F" }}>Info@edgeconnectsolutions.com </Typography>
                    </Box>
                </Box>
                <Box>
                    <FiMapPin fontSize={21} />
                    <Box>
                        <Typography fontSize={14} sx={{ color: '#7D7D7D' }}>Visit anytime</Typography>
                        <Typography variant='h5' fontSize={15} fontWeight={500} sx={{ color: "#37393F" }}>5571 James young way, Fairfax,Virginia 22032 </Typography>
                    </Box>
                </Box>


            </Grid>
        </Grid>
    )
}

export default LetsTalk