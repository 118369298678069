import React from 'react'
import styles from './Home.module.scss'
import { Grid, Typography } from '@mui/material'
import Banner from '../../components/banner/Banner'
import CommonSection from '../../components/CommonSection/CommonSection'
import img from '../../asserts/home/about.png'
import WeProvide from '../../components/WeProvide/WeProvide'
import achievement from '../../asserts/home/achievement.png'
import Records from '../../components/Records/Records'
import MarketingStrategy from '../../components/MarketingStrategy/MarketingStrategy'
import Testimonial from '../../components/Testimonial/Testimonial'

const Home = () => {
    return (
        <Grid className={styles.homeContainer}>
            <Banner />
            <CommonSection
                img={img}
                heading={'About us'}
                subHeading={`"Edge Connect Solutions: Where Talent Finds Its Perfect Match. Let's Build Success Together."`}
                desc={`Welcome to Edge Connect Solutions, where talent meets opportunity. As a premier staffing company, we specialize in connecting top-tier talent with leading organizations across diverse industries. With a deep understanding of our clients' needs and a commitment to excellence, we provide customized staffing solutions tailored to each unique requirement. Whether you're seeking temporary, permanent, or contract placements, our dedicated team of experts is here to support you every step of the way. Trust us to find the perfect fit for your organization or to help you discover exciting career opportunities. Let's build success together.`}
            />
            <WeProvide />
            <CommonSection
                img={achievement}
                heading={'Our Achievement'}
                subHeading={`"EDGE CONNECT: Setting new standards in talent solutions, recognized for excellence and trusted partnership in technology and talent acquisition."`}
                desc={`With a proven track record of excellence, we've successfully matched top-tier talent with leading organizations across diverse industries, delivering tailored staffing and consulting solutions. Our team's unwavering commitment to client satisfaction has earned us accolades for our exceptional service and industry expertise. From Fortune 500 companies to innovative startups, we've provided strategic workforce solutions that drive success and foster growth. 
                With a focus on quality, integrity, and innovation, we continue to surpass expectations, solidifying our position as a trusted partner in the ever-evolving landscape of staffing and consulting.`}
            />
            <Records />
            <MarketingStrategy />
            {/* <Testimonial /> */}
        </Grid>
    )
}

export default Home