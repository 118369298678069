import React from 'react'
import styles from './CommonSection.module.scss'
import { Grid, Typography } from '@mui/material'
import img from '../../asserts/home/about.png'
import HeadingText from '../HeadingText/HeadingText'

export interface ICommonSection {
    img: string;
    heading: string;
    subHeading: string;
    desc: string;

}
const CommonSection = ({ img, heading, subHeading, desc }: ICommonSection) => {
    return (
        <Grid container spacing={3} className={styles.commonSectionContainer}>
            <Grid item sm={5}>
                <img src={img} />
            </Grid>
            <Grid item sm={7}>
                <HeadingText
                    heading={heading}
                    subHeading={subHeading}
                    desc={desc}
                />
            </Grid>
        </Grid>
    )
}

export default CommonSection