import React from 'react'
import styles from './SimpleAccordion.module.scss'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { FaPlus } from "react-icons/fa6";
import { RiSubtractFill } from "react-icons/ri";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Heading from '../Heading/Heading';



const SimpleAccordion = () => {

    return (
        <Grid className={styles.simpleAccordionContainr}>
            <Heading
                heading='Frequently Asked Questions'
                desc={"Leveling customer service for state of the art  customer service innovate product  for reliable supply engage web services cutting-edge deliverables."}
            />
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>What is your pricing structure?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Our pricing is flexible and based on factors such as the type of service, duration, and complexity of the project. We offer competitive rates to ensure value for our clients.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>What is your process for onboarding consultants?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        We have a streamlined onboarding process that includes orientation, training, and ongoing support to ensure that consultants are fully prepared to excel in their roles.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Can you provide consultants for short-term projects?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Absolutely! We specialize in providing consultants for short-term projects, allowing clients to scale their teams as needed without long-term commitments.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Do you offer temporary or permanent staffing solutions?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Yes, we offer both temporary and permanent staffing solutions based on our clients' needs and preferences.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>What sets your staffing and consulting services apart from others?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Our personalized approach, industry expertise, extensive network, and commitment to client satisfaction set us apart as a trusted partner in IT staffing and consulting
                    </Typography>
                </AccordionDetails>
            </Accordion>

        </Grid>
    )
}

export default SimpleAccordion