import React, { Fragment } from 'react'
import { Grid, Typography } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/home/Home'
import Topbar from './components/topbar/Topbar'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import About from './pages/about/About'
import Service from './pages/service/Service'
import Carrer from './pages/carrer/Carrer'
import Contact from './pages/contact/Contact'
import styles from './App.module.scss'
import ResponseHeader from './components/ResponseHeader/ResponseHeader'
import DisclaimerPage from './pages/Disclaimer/DisclaimerPage'

const App = () => {
  return (
    <Grid className={styles.appContainer}>
      <Topbar />
      <Header />
      <ResponseHeader />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/service' element={<Service />} />
        <Route path='/career' element={<Carrer />} />
        <Route path='/contact' element={<Contact />} />
        <Route path="/Disclaimer" element={<DisclaimerPage/>}></Route>
      </Routes>
      <Footer />
    </Grid>
  )
}

export default App