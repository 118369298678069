import React from 'react'
import styles from './StrategyCard.module.scss'
import { Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';

export interface IStrategyCard {
    img: string;
    heading: string;
    desc: string;
}
const StrategyCard = ({ img, heading, desc }: IStrategyCard) => {
    const navigate = useNavigate()
    const handleClick = () => {
        window.scrollTo(0, 0)

    }
    return (
        <Grid className={styles.strategyCardContainer}>
            <img src={img} />
            <Typography variant='h4' fontSize={18} fontWeight={600} sx={{ color: "#03274A" }}>{heading}</Typography>
            <Typography fontSize={15} sx={{ color: "#6C747C" }}>{desc}</Typography>
            <Typography sx={{ color: "#11467A", cursor: "pointer" }} onClick={(() => { navigate('/'); handleClick() })}>Read more</Typography>
        </Grid>
    )
}

export default StrategyCard