import React from 'react'
import styles from './Heading.module.scss'
import { Grid, Typography } from '@mui/material'
import { FaSquare } from 'react-icons/fa6';

export interface IHeading {
    IsLabel?: boolean;
    label?: string;
    heading: string;
    desc?: string;
}
const Heading = ({ IsLabel, label, heading, desc }: IHeading) => {
    return (
        <Grid className={styles.headingContainer}>
            {IsLabel ? <Typography variant='h5' fontSize={16} fontWeight={500} sx={{ color: "#37393F" }}><FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />{label}</Typography> : ""}
            <Typography variant='h4' fontSize={25} fontWeight={600} sx={{ color: "#11467A" }}>{heading}</Typography>
            <Typography>{desc}</Typography>
        </Grid>
    )
}

export default Heading