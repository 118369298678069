import React from 'react'
import styles from './OurClients.module.scss'
import { Grid, Typography } from '@mui/material'
import Heading from '../Heading/Heading'
import img from '../../asserts/about/about.jpg'
import { clients } from '../../data/header'

const OurClients = () => {
    return (
        <Grid className={styles.ourClientsContainer}>
            <Heading
                IsLabel={true}
                label='Clients'
                heading={'Meet Our Clients'}
                desc={"Thank you for being a part of our journey. Together, we're shaping the future."}
            />
            <Grid className={styles.ourClients}>
                <img src={img} />
                <Grid className={styles.opecity}></Grid>
                <Grid className={styles.clients}>
                    {
                        clients.map((item) => {
                            return (
                                <img src={item.icon} />
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OurClients