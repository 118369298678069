import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Footer.module.scss'
import { Box, Grid, TextField, Typography } from '@mui/material'
import brand from '../../asserts/edge connect logo 1.svg'
import { FaArrowRight, FaInstagram } from "react-icons/fa6";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { FaLinkedinIn } from "react-icons/fa6";



const Footer = () => {
    const navigation = useNavigate();
    const handleNavigate = () => {
        window.scrollTo(0, 0)


    }
    return (
        <Grid container spacing={2} className={styles.footerContainer}>
            <Grid item sm={4} className={styles.barnd}>
                <img src={brand} width={110} onClick={(() => { navigation('/'); handleNavigate() })} />
                <Typography fontSize={14} marginBlockStart={1} fontWeight={400}>Staffing and consulting company providing tailored workforce solutions and expert guidance for business growth and efficiency. Connecting talent with opportunity.</Typography>
                <Link  to="/Disclaimer"  style={{
            textDecoration: 'none',
            color: 'inherit',
            cursor: 'pointer',
            display: 'inline-block',
            marginTop: '25px',
          }}>Disclaimer</Link>
            </Grid>
            <Grid className={styles.links}>
                <Typography variant='h4' fontSize={18} fontWeight={500}>Extra Links</Typography>
                <Grid className={styles.linksSection}>
                    <Box>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/'); handleNavigate() })} >Home</Typography>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/about'); handleNavigate() })}>About Us</Typography>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/service'); handleNavigate() })}>Service</Typography>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/'); handleNavigate() })}>Technology</Typography>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/'); handleNavigate() })}>News & Blogs</Typography>
                    </Box>
                    <Box>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/'); handleNavigate() })}>Digital Innovation</Typography>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/career'); handleNavigate() })}>Career</Typography>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/contact'); handleNavigate() })}>Contact Us</Typography>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/'); handleNavigate() })}>Privacy Policy</Typography>
                        <Typography fontSize={14} fontWeight={400} marginBlock={1} sx={{ cursor: "pointer" }} onClick={(() => { navigation('/'); handleNavigate() })}>Sitemap</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid className={styles.social}>
                <Typography variant='h4' fontSize={18} fontWeight={500}>Subscribe</Typography>
                <Typography fontSize={14} fontWeight={400} marginBlock={1}>Sign up for Alerts, Special Offers, Education and Updates</Typography>
                <Grid className={styles.subscribe}>
                    <TextField name='subscribe' placeholder='Enter your email' />
                    <FaArrowRight />
                </Grid>
                <Box>
                    <FaFacebookF fontSize={21} cursor={"pointer"} style={ {marginInline: 5} }/>
                    <FaInstagram fontSize={21} cursor={"pointer"} style={ {marginInline: 5} }/>
                    <FaLinkedinIn fontSize={21} cursor={"pointer"} style={{ marginInline: 5}} />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Footer