import React from 'react';
import './DisclaimerPage.scss';

const DisclaimerPage = () => {
  return (
   <div className='desclaimer-margin'>
     <div className="disclaimer-page">
      <div className="disclaimer-header">
        <h1>Disclaimer</h1>
      </div>

      <div className="disclaimer-content">
        <p>
          By providing my phone number to “EdgeConnect Solutions LLC”, I agree
          and acknowledge that “EdgeConnect Solutions LLC” may send text
          messages to my wireless phone number for any purpose. Message and data
          rates may apply. Message frequency will vary, and you will be able to
          Opt-out by replying “STOP”. For more information on how your data
          will be handled please see our privacy policy below:
        </p>
        <h2>Privacy Policy:</h2>
        <p>
          No mobile information will be shared with third parties/affiliates for
          marketing/promotional purposes. All the above categories exclude text
          messaging originator opt-in data and consent; this information will
          not be shared with any third parties.
        </p>
      </div>

      <div className="subscribe-section">
        <h2>Subscribe for your free account</h2>
        <div className="subscribe-form">
          <input type="email" placeholder="Enter your email address" />
          <button>REGISTER NOW</button>
        </div>
        <p className="note">
          Get access to free calculations and HS code lookups. No credit card needed.
        </p>
      </div>
    </div>
   </div>
  );
};

export default DisclaimerPage;
