import React from 'react'
import styles from './Topbar.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import { topbar } from '../../data/header'
import brand from '../../asserts/edge connect logo 1.svg'
import { useNavigate } from 'react-router-dom'

const Topbar = () => {
    const navigation = useNavigate()
    return (
        <Grid container className={styles.topbarContainer}>
            <Grid item sm={6} className={styles.brand}>
                <img src={brand} width={110} onClick={(() => navigation('/'))} style={{ cursor: "pointer" }} />
            </Grid>
            <Grid item sm={5} className={styles.contatc}>
                {topbar.map((item) => {
                    return (
                        <Grid className={styles.contatcInfo}>
                            <Box>{item.icon}</Box>
                            <Box>
                                <Typography fontSize={14} sx={{ color: "#6C747C" }}>{item.label}</Typography>
                                <Typography fontSize={14} sx={{ color: "#6C747C" }}>{item.subLabel}</Typography>
                            </Box>
                        </Grid>
                    )
                })}

            </Grid>

        </Grid>
    )
}

export default Topbar