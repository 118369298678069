import React from 'react'
import styles from './MarketingStrategy.module.scss'
import { Grid, Typography } from '@mui/material'
import Heading from '../Heading/Heading'
import { strategy } from '../../data/header'
import StrategyCard from '../StrategyCard/StrategyCard'

const MarketingStrategy = () => {
    return (
        <Grid className={styles.marketingStrategyContainer}>
            <Heading heading={'Marketing Strategy'} />
            <Grid container spacing={3} className={styles.cardContainer}>
                {strategy.map((item) => {
                    return (
                        <Grid item sm={4} className={styles.card}>
                            <StrategyCard
                                img={item.img}
                                heading={item.heading}
                                desc={item.desc}
                            />
                        </Grid>
                    )
                })}

            </Grid>
        </Grid>
    )
}

export default MarketingStrategy