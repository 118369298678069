import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import styles from './WeProvide.module.scss'
import { weProvide, weProvide1 } from '../../data/header'
import img from '../../asserts/icons/Icon.svg'
import { useNavigate } from 'react-router-dom'

const WeProvide = () => {
    const navigation = useNavigate()
    const handleNavigate = () => {
        window.scrollTo(0, 0)
    }
    return (
        <Grid className={styles.weProvideContainer}>
            <Typography variant='h5' fontWeight={500} fontSize={15} sx={{ color: "#37393F" }}>We Are Expertise In</Typography>
            <Typography variant='h4' fontSize={32} fontWeight={700} marginBlock={2} sx={{ color: "#37393F" }}>We Provide Professional <br />Business Solutions.</Typography>
            <Grid className={styles.cardContainer}>
                {weProvide.map((item) => {
                    return (
                        <Grid className={styles.card}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography variant='h4' fontSize={18} fontWeight={600} sx={{ color: "#37393F" }}>{item.label}</Typography>
                                    <Typography variant='h4' fontSize={18} fontWeight={600} sx={{ color: "#37393F" }}>{item.span}</Typography>
                                </Box>
                                <img src={item.icon} width={40} height={40} />
                            </Box>
                            <Typography onClick={() => { navigation('/'); handleNavigate() }} textAlign={"left"} variant='h5' fontSize={14} sx={{ color: "#6B77E5", cursor: "pointer" }}>Read more</Typography>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid className={styles.cardContainer2}>
                {weProvide1.map((item) => {
                    return (
                        <Grid className={styles.card}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography variant='h4' fontSize={18} fontWeight={600} sx={{ color: "#37393F" }}>{item.label}</Typography>
                                    <Typography variant='h4' fontSize={18} fontWeight={600} sx={{ color: "#37393F" }}>{item.span}</Typography>
                                </Box>
                                <img src={item.icon} width={40} height={40} />
                            </Box>
                            <Typography onClick={() => { navigation('/'); handleNavigate() }} textAlign={"left"} variant='h5' fontSize={14} sx={{ color: "#6B77E5", cursor: "pointer" }}>Read more</Typography>
                        </Grid>
                    )
                })}
                <Grid className={styles.more} >
                    <Typography textAlign={"center"} variant='h5' fontSize={18} sx={{ color: "#FFFFFF", }}>More services</Typography>
                </Grid>
            </Grid>
            <Grid container className={styles.responsiveCardContainer}>
                {weProvide.map((item) => {
                    return (
                        <Grid item sm={12} className={styles.card}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography variant='h4' fontSize={18} fontWeight={600} sx={{ color: "#37393F" }}>{item.label}</Typography>
                                    <Typography variant='h4' fontSize={18} fontWeight={600} sx={{ color: "#37393F" }}>{item.span}</Typography>
                                </Box>
                                <img src={item.icon} width={25} height={24} />
                            </Box>
                            <Typography onClick={() => { navigation('/'); handleNavigate() }} textAlign={"left"} variant='h5' fontSize={14} sx={{ color: "#6B77E5", cursor: 'pointer' }}>Read more</Typography>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default WeProvide