import React from 'react'
import styles from './Header.module.scss'
import { Grid, Typography } from '@mui/material'
import { menus } from '../../data/header'
import { useNavigate } from 'react-router-dom'

const Header = () => {
    const navigation = useNavigate()
    return (
        <Grid className={styles.headerContainer}>
            {menus.map((item) => {
                return (
                    <Typography key={item.id} onClick={(() => navigation(item.path))}>{item.label}</Typography>
                )
            })}
        </Grid>
    )
}

export default Header