import React from 'react'
import styles from './Service.module.scss'
import { Grid } from '@mui/material'
import CommonBanner from '../../components/about/CommonBanner'
import banner from '../../asserts/banner/career.png'
import ServiceSection from '../../components/ServiceSection/ServiceSection'

const Service = () => {
  return (
    <Grid className={styles.serviceContainer}>
      <CommonBanner
        bannerImg={banner}
        label={'About The Services'}
        heading={'Business Management'}
      />
      <ServiceSection />
    </Grid>
  )
}

export default Service