import React from 'react'
import styles from './AboutUsSection.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import img from '../../asserts/about/Image (1).png'
import Heading from '../Heading/Heading'
import SimpleButton from '../simpleButton/SimpleButton'
import { useNavigate } from 'react-router-dom'

const AboutUsSection = () => {
    const navigation = useNavigate()
    return (
        <Grid className={styles.aboutUsSectionContainer}>
            <Grid className={styles.leftSide}>
                <Box>
                    <img src={img} />
                    <Typography variant='h4' fontSize={25} fontWeight={600} marginBlock={1} sx={{ color: "#37393F" }}>We’re Ready To Grow Your Business With Us</Typography>
                    <Typography fontSize={16} marginBlock={2} sx={{ color: "#7D7D7D" }}>Ready to elevate your business? Partner with us for growth, innovation, and success. Let's embark on this journey together. </Typography>
                    <Typography variant='h5' fontSize={16} fontWeight={600} marginBlock={1} sx={{ color: "#37393F", cursor: "pointer" }} onClick={() => navigation('/contact')}>Get In Touch</Typography>
                </Box>
            </Grid>
            <Grid className={styles.rightSide}>
                <Heading
                    IsLabel={true}
                    label='About Us'
                    heading={'We Care About Your Business Plan.'}
                />
                <Typography fontSize={15} marginBlock={2} sx={{ color: "#7D7D7D" }}>At Edge Connect Solutions, we prioritize your business success. Our staffing and consulting services are tailored to meet your specific needs, ensuring optimal workforce solutions and expert guidance. With a deep understanding of industry trends and challenges, we collaborate closely with you to develop strategic plans that drive growth and efficiency. From talent acquisition to HR consulting, we are committed to providing comprehensive support every step of the way. Trust us to care for your business as if it were our own, delivering measurable results and long-term partnerships built on integrity, transparency, and excellence.</Typography>
                <Box>
                    <Typography variant='h5' fontSize={18} fontWeight={600} sx={{ color: "#37393F" }}>Our Vision</Typography>
                    <Typography fontSize={15} marginBlockStart={2} sx={{ color: "#7D7D7D" }}>"To empower organizations and individuals to thrive through innovative staffing solutions and strategic consulting services, driving sustainable growth and success in an ever-evolving business landscape."</Typography>
                </Box>
                <Box>
                    <Typography variant='h5' fontSize={18} fontWeight={600} sx={{ color: "#37393F" }}>Our Mission</Typography>
                    <Typography fontSize={15} marginBlockStart={2} sx={{ color: "#7D7D7D" }}>"Our mission is to connect top talent with leading organizations, providing tailored staffing solutions and strategic consulting services that inspire confidence, drive results, and foster long-term partnerships. We are committed to delivering excellence, integrity, and value in everything we do, empowering our clients and candidates to reach their full potential."</Typography>
                </Box>
                <SimpleButton
                    name='More about us'
                />
            </Grid>
        </Grid>
    )
}

export default AboutUsSection