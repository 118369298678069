import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import SimpleInputField from '../SimpleInputField/SimpleInputField'
import styles from './RecruitForm.module.scss'
import Heading from '../Heading/Heading'
import SimpleButton from '../simpleButton/SimpleButton'
import { FaSquare } from "react-icons/fa";


const RecruitForm = () => {
  return (
    <Grid className={styles.recruitFormContainer}>
      <Heading
        IsLabel={false}
        label='"Recruitment"'
        heading={'We assist in sourcing top-tier candidates for '}
      />
      <Grid className={styles.recruitForm}>
        <Box >
          <Typography><FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />AR, VR, & IoT</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />Data Science</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />eCommerce Executive</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />Machine Learning</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />Software Development</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />Digital Marketing</Typography>
        </Box>
        <Box>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />BI and Big Data</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />DBA & DevOps</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />Infrastructure & Support</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />QA Engineers</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />Web & Mobile Development</Typography>
          <Typography> <FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />Cloud Cybersecurity</Typography>
        </Box>
      </Grid>
      {/* <SimpleButton
        name={'Submit'}
      /> */}
    </Grid>
  )
}

export default RecruitForm