import React from 'react'
import styles from './CommonBanner.module.scss'
import { Grid, Typography } from '@mui/material'
import banner from '../../asserts/about/about.png'
import { FaSquare } from 'react-icons/fa6'

export interface ICommonBanner {
    bannerImg: string;
    label: string;
    heading: string;
    desc?: string;

}
const CommonBanner = ({ bannerImg, label, heading, desc }: ICommonBanner) => {
    return (
        <Grid className={styles.aboutBannerContainer}>
            <img src={bannerImg} />
            <Grid className={styles.aboutBanner}>
                <Grid>
                    <Typography variant='h5' fontSize={16} fontWeight={500} sx={{ color: "#37393F" }}><FaSquare fontSize={13} style={{ color: "#40DDB6", marginInlineEnd: 4 }} />{label}</Typography>
                    <Typography marginBlockStart={1} marginBlockEnd={2} variant='h4' fontSize={35} fontWeight={700}>{heading}</Typography>
                    <Typography fontSize={14} fontWeight={400}>{desc}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CommonBanner