import React from 'react'
import styles from './ServiceSection.module.scss'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { IoIosArrowForward, IoMdCheckmark } from "react-icons/io";
import { services } from '../../data/header'
import img from '../../asserts/service/service.jpg'
import benefits from '../../asserts/service/benefits.jpg'
import { FaRegFilePdf } from "react-icons/fa6";
import { PiFilePdfDuotone, PiFileZipDuotone } from "react-icons/pi";
import q_img from '../../asserts/service/haveQuation.png'
import Heading from '../Heading/Heading';
import SimpleButton from '../simpleButton/SimpleButton';
import SimpleAccordion from '../SimpleAccordion/SimpleAccordion';


const ServiceSection = () => {
    const handleClick = (label: any) => {
        console.log(label, "label//")

    }
    return (
        <Grid className={styles.serviceSectionContainer}>
            <Grid className={styles.leftSide}>
                <Box>
                    <Grid className={styles.services}>
                        <Typography variant='h4' fontSize={21} fontWeight={600} sx={{ color: "#37393F", marginBlockEnd: 3 }}>Services</Typography>
                        {services.map((item) => {
                            return (
                                <Box onClick={() => handleClick(item.label)}>
                                    <Typography variant='h5' fontSize={15} fontWeight={400} sx={{ color: "#37393F" }}>{item.label}</Typography>
                                    <IoIosArrowForward fontSize={16} style={{ color: "#37393F" }} />
                                </Box>
                            )
                        })}

                    </Grid>
                    <Grid className={styles.download}>
                        <Typography variant='h4' fontSize={21} fontWeight={600} sx={{ color: "#37393F", marginBlockEnd: 3 }}>Download</Typography>
                        <Divider sx={{ marginBlock: 1, }} />
                        <Typography fontSize={14} sx={{ color: "#7D7D7D" }}>One-to-one customer service with robust deliverables.</Typography>
                        <Box>
                            <PiFilePdfDuotone fontSize={22} />
                            <Typography variant='h5' fontSize={13} fontWeight={500}>Document.pdf</Typography>

                        </Box>
                        <Box>
                            <PiFileZipDuotone fontSize={22} />
                            <Typography variant='h5' fontSize={13} fontWeight={500}>Document.zip</Typography>
                        </Box>


                    </Grid>
                    <Grid className={styles.haveQuation}>
                        <img src={q_img} />
                        <Grid>
                            <Heading
                                IsLabel={true}
                                label='Have any Quations?'
                                heading={'Do you need'}
                            />
                            <Typography variant='h4' fontSize={32} fontWeight={700} sx={{ color: "#ffffff" }}>any help?</Typography>
                            <Typography fontSize={14} sx={{ color: "#37393F", marginBlockStart: 0.6 }}>Require assistance? Can I lend a hand?</Typography>
                            <SimpleButton name={'Contact Us'} />
                        </Grid>

                    </Grid>
                </Box>
            </Grid>
            <Grid className={styles.rightSide}>
                <Grid className={styles.servicesDetails}>
                    <img src={img} />
                    <Typography variant='h4' fontSize={25} fontWeight={600} sx={{ color: "#37393F", marginBlock: 2 }}>Why Us For International Business  Management Consulting!</Typography>
                    <Typography fontSize={14} sx={{ color: "#7D7D7D", lineHeight: 1.8 }}>Completely synergized resourced taxings relationships premiers markets ultivate one-to-one customer service with robust ideas dynamically innovated resources leveling customer service for state of the art  customer service innovate product  for reliable supply engage web services cutting-edge deliverables.</Typography>
                    <Typography variant='h5' fontSize={18} fontWeight={500} sx={{ color: '#6B77E5', marginBlock: 2 }}>Proactively envisioned multimedia based expertise media growth  superior collaboration.</Typography>
                    <Typography fontSize={14} sx={{ color: "#7D7D7D", lineHeight: 1.8 }}>One-to-one customer service with robust ideas dynamically innovated resources for reliable supply engage web services cutting-edge deliverables.</Typography>
                    <Grid className={styles.ourBenefits}>
                        <Box>
                            <img src={benefits} />
                        </Box>
                        <Box>
                            <Typography variant='h4' fontSize={23} fontWeight={600} sx={{ color: "#37393F" }}>Our Benefits</Typography>
                            <Typography variant='h5' fontSize={16} fontWeight={500} sx={{ color: '#40DDB6', marginBlockStart: 1.5, marginBlockEnd: 3, lineHeight: 1.6 }}>Incubate standards compliant channels benefits.</Typography>
                            <Box>
                                <Box>
                                    <IoMdCheckmark fontSize={14} style={{ color: "#ffffff", backgroundColor: "#6B77E5" }} />
                                    <Typography fontSize={14} sx={{ color: "#7D7D7D" }}>Credibly reintermediate backend</Typography>
                                </Box>
                                <Box>
                                    <IoMdCheckmark fontSize={14} style={{ color: "#ffffff", backgroundColor: "#6B77E5" }} />
                                    <Typography fontSize={14} sx={{ color: "#7D7D7D" }}>Holistically foster superior</Typography>
                                </Box>
                                <Box>
                                    <IoMdCheckmark fontSize={14} style={{ color: "#ffffff", backgroundColor: "#6B77E5" }} />
                                    <Typography fontSize={14} sx={{ color: "#7D7D7D" }}>Distinctively exploit optimal</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <SimpleAccordion />
            </Grid>
        </Grid>
    )
}

export default ServiceSection