import React, { useState } from 'react'
import { Box, Grid, TextField, TextareaAutosize } from '@mui/material'
import styles from './Carrer.module.scss'
import CommonBanner from '../../components/about/CommonBanner'
import img from '../../asserts/banner/career.png'
import Heading from '../../components/Heading/Heading'
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField'
import SimpleButton from '../../components/simpleButton/SimpleButton'
import { baseUrl } from '../../utils/constrain'

const Carrer = () => {
  const [inputs, setInputs] = useState<{
    userName: string;
    email: string;
    mobile: string;
    message: string;
    subject: string;
  }>({
    userName: '',
    email: '',
    mobile: '',
    message: '',
    subject: '',

  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputs(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitRequest = async () => {
    const url = `${baseUrl}/general/email/submit/request`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(inputs)
    };

    try {
      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      alert(data.message || 'Request submitted successfully:')
      setInputs({
        userName: '',
        email: '',
        mobile: '',
        message: '',
        subject: '',
      })
      console.log('Request submitted successfully:', data);
    } catch (error) {
      console.error('Error submitting request:', error);
    }
  };

  return (
    <Grid className={styles.carrerContainer}>
      <CommonBanner
        bannerImg={img}
        label={'Career'}
        heading={'"Explore Opportunities, Embrace Success"'}
      />
      <Heading
        IsLabel={true}
        label='Career'
        heading='Apply Hare'
      />
      <Grid className={styles.carrerForm}>
        <Box>
          <Box>
            <SimpleInputField
              name="userName"
              type="text"
              placeholder="Enter your username"
              value={inputs.userName}
              handleInputChange={handleInputChange}
            />

            <SimpleInputField
              name="mobile"
              type="text"
              placeholder="Phone Number"
              value={inputs.mobile}
              handleInputChange={handleInputChange}
            />

            <TextField placeholder='Select file' type='file' className={styles.selectFile} />
          </Box>
          <Box>
            <SimpleInputField
              name="email"
              type="text"
              placeholder="Your Email"
              value={inputs.email}
              handleInputChange={handleInputChange}
            />

            <SimpleInputField
              name="subject"
              type="text"
              placeholder="Subject"
              value={inputs.subject}
              handleInputChange={handleInputChange}
            />

          </Box>
        </Box>
        <TextareaAutosize minRows={6} placeholder='Message' onChange={handleTextAreaChange} />
        <SimpleButton name={'Submit'} handleClick={submitRequest} />
      </Grid>
    </Grid>
  )
}

export default Carrer