import React from 'react'
import styles from './Banner.module.scss'
import { Grid, Typography } from '@mui/material'
import banner from '../../asserts/banner/banner-2 1.png'
import SimpleButton from '../simpleButton/SimpleButton'

const Banner = () => {
    return (
        <Grid className={styles.bannerContainer}>
            <Grid className={styles.img}>
                <img src={banner} />
            </Grid>
            <Grid className={styles.opecity}></Grid>
            <Grid className={styles.desc}>
                <Typography variant='h4' fontSize={38} fontWeight={600}>Beyond resumes, we find the talent that ignites your innovation</Typography>
                <Typography fontSize={14}>Establish, Grow and Scale the Business with us</Typography>
                <SimpleButton name={'Learn more'} />
            </Grid>
        </Grid>
    )
}

export default Banner