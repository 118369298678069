import React, { useState } from 'react'
import styles from './ContactForm.module.scss'
import { Box, Grid, Typography, TextareaAutosize } from '@mui/material'
import Heading from '../Heading/Heading'
import SimpleInputField from '../SimpleInputField/SimpleInputField'
import SimpleButton from '../simpleButton/SimpleButton'
import { baseUrl } from '../../utils/constrain'

export interface IContactForm {
    textDesc: string;
    handleChange: any;
}
const ContactForm = () => {

    const [inputs, setInputs] = useState<{
        userName: string;
        email: string;
        mobile: string;
        message: string;
        subject: string;
    }>({
        userName: '',
        email: '',
        mobile: '',
        message: '',
        subject: '',

    });


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputs(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setInputs(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submitRequest = async () => {
        const url = `${baseUrl}/general/email/submit/request`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(inputs)
        };

        try {
            const response = await fetch(url, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            alert(data.message||'Request submitted successfully:')
            setInputs({
                userName: '',
                email: '',
                mobile: '',
                message: '',
                subject: '',
            })
            console.log('Request submitted successfully:', data);
        } catch (error) {
            console.error('Error submitting request:', error);
        }
    };



    return (
        <Grid className={styles.contactFormContainer}>
            <Heading
                IsLabel={true}
                label='Contact Us'
                heading={'Request A Call Back !'}
            />
            <Grid className={styles.contactForm}>
                <Box>

                    <SimpleInputField
                        name="userName"
                        type="text"
                        placeholder="Enter your username"
                        value={inputs.userName}
                        handleInputChange={handleInputChange}
                    />
                  
                    <SimpleInputField
                        name="mobile"
                        type="text"
                        placeholder="Phone Number"
                        value={inputs.mobile}
                        handleInputChange={handleInputChange}
                    />
                 
                </Box>
                <Box>
                    <SimpleInputField
                        name="email"
                        type="text"
                        placeholder="Your Email"
                        value={inputs.email}
                        handleInputChange={handleInputChange}
                    />
                 
                    <SimpleInputField
                        name="subject"
                        type="text"
                        placeholder="Subject"
                        value={inputs.subject}
                        handleInputChange={handleInputChange}
                    />
               
                </Box>

            </Grid>
            <TextareaAutosize minRows={6} placeholder='Message' name="message" onChange={handleTextAreaChange} className={styles.textMessage} />
            <SimpleButton name={'Submit'} handleClick={submitRequest}/>
        </Grid>
    )
}

export default ContactForm