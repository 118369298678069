import React from 'react';
import { Grid, TextField } from '@mui/material';
import styles from './SimpleInputField.module.scss';

export interface ISimpleInputField {
    placeholder: string;
    name: string;
    type: string;
    value: string;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const SimpleInputField: React.FC<ISimpleInputField> = ({ name, type, placeholder, value, handleInputChange, disabled = false }: ISimpleInputField) => {
    return (
        <Grid className={styles.simpleInputFieldContainer}>
            <TextField
                disabled={disabled}
                placeholder={placeholder}
                name={name}
                type={type}
                value={value}
                onChange={handleInputChange}
            />
        </Grid>
    );
};

export default SimpleInputField;
