import React, { useState } from 'react'
import styles from './ResponseHeader.module.scss'
import { Box, Grid, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { IoReorderThreeSharp } from "react-icons/io5";
import logo from '../../asserts/edge connect logo 1.svg'
import { useNavigate } from 'react-router';


const ResponseHeader = () => {
    const navigation = useNavigate()
    const [open, setOpen] = useState(false)
    const handleToggel = () => {
        setOpen(!open)

    }
    const menu = [
        {
            "id": 1,
            "label": "Home",
            "path": "/"
        }, {
            "id": 1,
            "label": "About Us",
            "path": "/about"
        },
        {
            "id": 1,
            "label": "Services",
            "path": "/service"
        },
        {
            "id": 1,
            "label": "Career",
            "path": "/career"
        },
        {
            "id": 1,
            "label": "Contact Us",
            "path": "/contact"
        }
    ]
    return (
        <Grid container className={styles.responseHeaderContainer}>
            <Box>
                <img src={logo} width={100} />
                <IoReorderThreeSharp fontSize={35} cursor={"pointer"} onClick={(() => handleToggel())} />
            </Box>
            {open && <List>
                {menu.map((item) => {
                    return (
                        <ListItem>
                            <ListItemButton>
                                <ListItemText onClick={(() => { navigation(item.path); handleToggel() })} primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>}

        </Grid>
    )
}

export default ResponseHeader