import React from 'react'
import styles from './Records.module.scss'
import { Grid, Typography } from '@mui/material'
import { record } from '../../data/header'

const Records = () => {
    return (
        <Grid container className={styles.recordsContainer}>
            {record.map((item) => {
                return (
                    <Grid item sm={3} className={styles.card}>
                        <Typography variant='h4' fontSize={25} fontWeight={600}>{item.number}</Typography>
                        <Typography variant='h5' fontSize={15} marginBlockStart={0.5}>{item.label}</Typography>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default Records